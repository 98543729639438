// DEPENDANCES
import React, { useState } from 'react';

// PICTURES
import Praline from '../../Assets/Recipe/praline.jpg';
import step1 from '../../Assets/Recipe/step1.jpg';
import step2 from '../../Assets/Recipe/step2.jpg';
import step3 from '../../Assets/Recipe/step3.jpg';

// COMPOSANT RECIPE
function Recipe() {
  const [language, setLanguage] = useState('en'); // 'en' pour anglais, 'fr' pour français
  const googleReviewLink =
    'https://www.google.fr/search?q=Chef%20Lyon%20Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIyMjcwNzQ2MzIxNjUxMjS0MLDYwMj4ipHPOSM1TcGnMj9PwbEss3gRK5oAAI_GNlE7AAAA&rldimm=270713624354211808&tbm=lcl&cs=1&hl=fr&sa=X&ved=0CB8Q9fQKKABqFwoTCODwhYHwwIkDFQAAAAAdAAAAABAG&biw=1440&bih=794&dpr=2#lkt=LocalPoiReviews';

  // COMPONENT
  return (
    <main className="recipe">
      {/* Bandeau */}
      <div className="RecipeBand">
        <img src={Praline} alt="Zoom sur les pralines roses de Lyon" />
      </div>

      {/* Présentation de la recette */}
      <section className="RecipePresentation">
        <h2>
          {language === 'fr'
            ? 'TARTE AUX PRALINES ROSES DE LYON'
            : 'PINK PRALINE TART FROM LYON'}
        </h2>
        <p className="RecipeDescription">
          {language === 'fr'
            ? "La praline rose est une confiserie emblématique de Lyon, composée d'amandes enrobées de sucre d'un rose éclatant. Ce délice tiendrait son nom du comte de Plessis-Praslin, qui aurait utilisé cette recette pour apaiser son indigestion. Originellement non colorée, la praline aurait pris sa teinte rose vers 1870, quand un pâtissier lyonnais, inspiré par les roseraies de Lyon, décida de rendre ces amandes plus attrayantes."
            : 'The praline rose is a famous confection from Lyon, made of almonds coated in a vibrant pink sugar. This delightful treat is said to have been named after the Count of Plessis-Praslin, who allegedly used this recipe to soothe his indigestion. Originally colorless, the praline is believed to have taken its pink hue around 1870, when a Lyonnais pastry chef, inspired by the rose gardens of Lyon, decided to make the almonds more visually appealing.'}
        </p>
      </section>

      {/* Bandeau */}
      <div className="RecipeBand">
        <img src={Praline} alt="Zoom sur les pralines roses de Lyon" />
      </div>

      {/* Langage */}
      <div className="SetLangage">
        <button
          className="Langages leftlangage"
          onClick={() => setLanguage('fr')}
        >
          <p className="Langage">FRANÇAIS</p>
        </button>
        <button className="Langages" onClick={() => setLanguage('en')}>
          <p className="Langage">ENGLISH</p>
        </button>
      </div>

      {/* Recette */}
      <div className="RecipeWindow">
        {/* ETAPE1 */}
        <section>
          <h3>
            {language === 'fr'
              ? 'Première étape : La préparation de notre tarte'
              : 'First step: Preparing our tart'}
          </h3>
          {/* Première étape : préparation de la tarte */}
          <div className="RecipeEtape">
            {/* Picture */}
            <div className="RecipePicture">
              <img
                src={step2}
                alt="Creme de pralines roses liquide versée dans une pates à tarte"
              />
            </div>
            {/* Ingrédients */}
            <div className="IsNeeded">
              <div className="BoxIngredient">
                <h4>
                  {language === 'fr'
                    ? 'Liste des ingrédients'
                    : 'List of ingredients'}
                </h4>
                <ul className="RecipeIngredient">
                  <li className="ingredient">
                    {language === 'fr' ? '200 g de farine' : '200 g of flour'}
                  </li>
                  <li className="ingredient">
                    {language === 'fr' ? '100 g de sucre' : '100 g of sugar'}
                  </li>
                  <li className="ingredient">
                    {language === 'fr'
                      ? '60 g de semoule fine'
                      : '60 g of fine semolina'}
                  </li>
                  <li className="ingredient">
                    {language === 'fr' ? '100 g de beurre' : '100 g of butter'}
                  </li>
                  <li className="ingredient">
                    {language === 'fr' ? '1 œuf' : '1 egg'}
                  </li>
                  <li className="ingredient">
                    {language === 'fr'
                      ? '1 pincée de sel de Guérande'
                      : '1 pinch of Guérande salt'}
                  </li>
                </ul>
              </div>
              {/* Indications */}
              <div className="BoxStep">
                <ul className="RecipeStep">
                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? "1. Pour commencer, mélangez le sucre, la farine et le beurre jusqu'à obtenir un sablage. Ensuite, ajoutez la semoule et le sel, puis incorporez l'œuf, et continuez à pétrir la pâte jusqu'à ce qu'elle soit homogène."
                        : '1. To begin, mix the sugar, flour, and butter until you achieve a crumbly texture. Then, add the semolina and salt, followed by the egg, and continue kneading the dough until it is smooth and homogeneous.'}
                    </p>
                  </li>

                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? '2. Formez un rouleau avec la pâte et réfrigérez-le pendant quelques minutes avant de l’abaisser ; ensuite, étalez-la finement entre deux feuilles de papier sulfurisé.'
                        : '2. Shape the dough into a log and refrigerate it for a few minutes before rolling it out; then, roll it out thinly between two sheets of parchment paper.'}
                    </p>
                  </li>

                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? '3. Détaillez un cercle de la taille du fond de votre moule à tarte, puis découpez une bande pour former le tour de la tarte et roulez cette bande en escargot.'
                        : '3. Cut out a circle the size of the bottom of your tart pan, then cut a strip to form the edge of the tart and roll this strip into a spiral.'}
                    </p>
                  </li>

                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? "4. Déroulez le ruban de pâte à l'intérieur du cercle à tarte, puis ajoutez le fond de la tarte par-dessus. Ensuite, utilisez un couteau pour couper tout excès de pâte qui déborde, et conservez la tarte au froid sur une plaque pour qu'elle garde sa forme."
                        : '4. Unroll the strip of dough inside the tart ring, then add the dough circle on top. Next, use a knife to trim any excess dough that overhangs, and keep the tart chilled on a tray to maintain its shape.'}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* ETAPE2 */}
        <section>
          <h3>
            {language === 'fr'
              ? 'Deuxième étape : La préparation de notre crème de Praline'
              : 'Step two: Preparing our Praline Cream'}
          </h3>
          {/* Deuxième étape : préparation de la crème de Praline */}
          <div className="RecipeEtape">
            {/* Picture */}
            <div className="RecipePicture">
              <img
                src={step1}
                alt="Creme de pralines roses liquide versée dans une pates à tarte"
              />
            </div>
            <div className="IsNeeded">
              {/* Ingrédients */}
              <div className="BoxIngredient">
                <h4>
                  {language === 'fr'
                    ? 'Liste des ingrédients'
                    : 'List of ingredients'}
                </h4>
                <ul className="RecipeIngredient">
                  <li className="ingredient" id="ING1">
                    {language === 'fr'
                      ? '150 g de pralines roses concassées'
                      : '150 g of crushed pink pralines'}
                  </li>
                  <li className="ingredient" id="ING2">
                    {language === 'fr'
                      ? '150 g de crème liquide (20% de matière grasse)'
                      : '150 g of heavy cream (20% fat)'}
                  </li>
                </ul>
              </div>
              {/* Indications */}
              <div className="BoxStep">
                <ul className="RecipeStep">
                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? "1. Mélangez les pralines concassées avec la crème liquide et faites cuire le mélange à feu moyen, sans arrêter de remuer avec une spatule, jusqu'à ce qu'il atteigne 109°C, ce qui prendra environ 6 à 10 minutes."
                        : '1. Mix the crushed pralines with the heavy cream and cook the mixture over medium heat, stirring constantly with a spatula, until it reaches 109°C. This will take about 6 to 10 minutes.'}
                    </p>
                  </li>

                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? '2. Une fois que la crème de praline est prête, versez-la délicatement sur le fond de tarte.'
                        : '2. Once the praline cream is ready, gently pour it over the tart base.'}
                    </p>
                  </li>

                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? '3. Ensuite, enfournez la tarte et laissez-la cuire environ 16 à 20 minutes à 170°C.'
                        : '3. Next, place the tart in the oven and bake for about 16 to 20 minutes at 170°C.'}
                    </p>
                  </li>

                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? ' 4. Enfin, retirez le cercle et déposez la tarte sur un carton or pour la présenter.'
                        : '4. Finally, remove the ring and place the tart on a gold cake board for presentation.'}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* ETAPE3 */}
        <section>
          <h3>
            {language === 'fr'
              ? 'Troisième étape : La préparation de nos Pralines roses de Lyon'
              : 'Step Three: Preparing our Pink Pralines from Lyon'}
          </h3>
          {/* Troisième étape : préparation des Pralines roses */}
          <div className="RecipeEtape">
            {/* Picture */}
            <div className="RecipePicture">
              <img
                src={step3}
                alt="Creme de pralines roses liquide versée dans une pates à tarte"
              />
            </div>
            <div className="IsNeeded">
              {/* Ingrédients */}
              <div className="BoxIngredient">
                <h4>
                  {language === 'fr'
                    ? 'Liste des ingrédients'
                    : 'List of ingredients'}
                </h4>
                <ul className="RecipeIngredient">
                  <li className="ingredient">
                    {language === 'fr'
                      ? "100 g d'amandes, avec la peau"
                      : '100 g of almonds, with the skin'}
                  </li>
                  <li className="ingredient">
                    {language === 'fr'
                      ? '150 g de sucre en poudre'
                      : '150 g of granulated sugar'}
                  </li>
                  <li className="ingredient">
                    {language === 'fr'
                      ? 'Colorant alimentaire rouge'
                      : 'Red food coloring'}
                  </li>
                  <li className="ingredient">
                    {language === 'fr' ? 'Vanille liquide' : 'Liquid vanilla'}
                  </li>
                  <li className="ingredient">
                    {language === 'fr'
                      ? "Extrait d'amande amère"
                      : 'Bitter almond extract'}
                  </li>
                </ul>
              </div>
              {/* Indications */}
              <div className="BoxStep">
                <ul className="RecipeStep">
                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? "1. Dans une petite casserole propre, versez 50 g de sucre, 2 cuillères à soupe d'eau et 2 ou 3 gouttes de vanille liquide, puis portez le mélange à ébullition jusqu'à obtenir une consistance proche de celle du miel, avant d'y ajouter les amandes."
                        : '1. In a small clean saucepan, combine 50 g of sugar, 2 tablespoons of water, and 2 or 3 drops of liquid vanilla. Bring the mixture to a boil until it reaches a honey-like consistency, then add the almonds.'}
                    </p>
                  </li>

                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? "2. Hors du feu, remuez vigoureusement les amandes avec une cuillère en bois ; le sucre va cristalliser et devenir blanc. Continuez à mélanger pendant environ 1 minute jusqu'à ce que toutes les amandes soient bien enrobées de sucre blond, laissant le reste du sucre sous forme de sable fin au fond de la casserole."
                        : '2. Off the heat, stir the almonds vigorously with a wooden spoon; the sugar will crystallize and turn white. Continue mixing for about 1 minute until all the almonds are well coated with golden sugar, leaving the remaining sugar as fine sand at the bottom of the saucepan.'}
                    </p>
                  </li>

                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? '3. Versez le tout dans une assiette et séparez les amandes du sucre en poudre, en faisant attention car les amandes sont très chaudes.'
                        : '3. Pour everything onto a plate and separate the almonds from the powdered sugar, being careful as the almonds will be very hot.'}
                    </p>
                  </li>

                  <li className="Step">
                    <p className="stepText">
                      {language === 'fr'
                        ? "4. Dans votre casserole propre, ajoutez à nouveau 50 g de sucre, une cuillère d'eau, une touche de colorant rouge et 2 gouttes d'extrait d'amande amère, puis procédez de la même manière qu'auparavant pour obtenir le mélange coloré."
                        : '4. In your clean saucepan, add another 50 g of sugar, a spoonful of water, a touch of red food coloring, and 2 drops of bitter almond extract. Then proceed in the same way as before to achieve the colored mixture.'}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* END */}
        <p className="lastTitle">
          {language === 'fr'
            ? "Il ne reste plus qu'a déguster !"
            : "All that's left is to enjoy!"}
        </p>

        {/* Note du Chef */}
        <section className="NoteChef">
          <h3>{language === 'fr' ? 'La note du Chef' : "The Chef's Tip"}</h3>
          <p>
            {language === 'fr'
              ? "Nous avons été ravis de vous accueillir et espérons que vous avez passé un agréable moment en notre compagnie. Nous serions enchantés que cette recette devienne l'une de vos favorites à préparer lors de vos prochaines rencontres avec vos amis. Bon appétit et à très bientôt en cuisine !"
              : 'We were delighted to welcome you and hope you had a wonderful time with us. We would be thrilled if this recipe becomes one of your favorites to prepare during your next gatherings with friends. Bon appétit, and we look forward to seeing you again in the kitchen very soon!'}
          </p>
          <p className="signature">Thierry Rodolphe</p>
        </section>

        {/* GOOGLE */}
        <a
          className="GoGoogle"
          href={googleReviewLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {language === 'fr'
            ? "N'hésitez pas à nous donner votre avis !"
            : 'Feel free to share your feedback with us!'}
        </a>
      </div>
    </main>
  );
}

export default Recipe;
