const CoursParticuliers = [
  /* VISITES TRABOULES */
  {
    langue: '🇫🇷',
    nom: 'Visite des traboules ou du marché',
    prix: '155,00 € / par personne',
    durée: 'Entre 4 et 5 heures',
    description:
      "Cours de cuisine suivi d'un repas : Avant le cours de cuisine, nous vous proposons une visite du marché Saint-Antoine pour finaliser les courses ou bien la visite des traboules. Le repas réalisé pendant ce cours sera composé d’une entrée, d’un plat, et d’un dessert « cuisine du marché », le tout agrémenté de vin locaux.",
    language: '🇬🇧',
    name: 'Visit to the traboules or the market',
    price: '155,00 € / per person',
    duration: 'Between 4 and 5 hours',
    ukDescription:
      'Cooking lesson followed by lunch : Before the cooking class, we suggest a visit to the Saint-Antoine market to finalize the shopping or a visit to the traboules. The meal made during this course will consist of a starter, a main course, and a « market cuisine » dessert, all accompanied by local wine.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/visite-des-traboules-ou-du-march%C3%A9',
  },
  /* REPAS LYONNAIS */
  {
    langue: '🇫🇷',
    nom: 'Cuisinons un repas Lyonnais ensemble',
    prix: '120,00 € / par personne',
    durée: 'Durée prévue : 3h30',
    description:
      'Dans la tradition des « Mères Lyonnaises » : La formule est composée de l’entrée, du plat, et du dessert avec boissons. Cette prestation comporte une découpe de volaille, sa cuisson ainsi que la préparation intégrale de l’entrée et du dessert. Le repas sera ensuite partagé au restaurant accompagné de vin ou de boisson soft. Exemple de menu proposé : Gougère au fromage et cervelle de canut, Poulet au vinaigre échalotes et crème, Tarte aux pralines roses.',
    language: '🇬🇧',
    name: "Let's cook a Lyonnais meal together",
    price: '120,00 € / per person',
    duration: 'Cooking class: 3h30',
    ukDescription:
      'In the tradition of the “Mères Lyonnaises” : The formula is composed of the starter, the main course, and the dessert with drinks. This service includes a cut of poultry, its cooking as well as the complete preparation of the starter and the dessert. The meal will then be shared in the restaurant accompanied by wine or soft drink. Example : Gougère au fromage et cervelle de canut, Poulet au vinaigre échalotes et crème, Tarte aux pralines roses.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/cuisinons-un-repas-lyonnais-ensemble',
  },
  /* ATELIER CONSERVES */
  {
    langue: '🇫🇷',
    nom: 'Atelier conserves et confitures',
    prix: '120,00 € / par personne',
    durée: 'Durée prévue : 3h30',
    description:
      'Comme au bon vieux temps : Aujourd’hui, il est de plus en plus nécessaire de savoir ce que l‘on mange. Confiture maison, pâtes à tartiner, ketchup, pâtés, pickles ou foie gras… Venez fabriquer vos propres conserves. Je vous propose de retrouver ces sensations lors de cours de cuisine aux côtés d’un vrai chef de cuisine qui vous initiera aux recettes originales et traditionnelles en fonction de vos envies et des produits de saison. Je vous transmettrai mes « secrets » et techniques avec des recettes simples mais originales. Vous repartirez avec les recettes, les « trucs » et astuces ainsi que les produits de votre composition.',
    language: '🇬🇧',
    name: 'Preserves and jams workshop',
    price: '120,00 € / per person',
    duration: 'Expected duration: 3h30',
    ukDescription:
      "Just like the good old days: Today it's more and more necessary to know what you are eating. Homemade jam, spreads, ketchup, pâtés, pickles or foie gras... Come and make your own preserves. I suggest you rediscover these sensations during cooking classes alongside a real chef who will introduce you to original and traditional recipes based on your desires and seasonal products. I will pass on my “secrets” and techniques to you with simple but original recipes. You will leave with the recipes, the “tips” and tricks as well as the products of your composition.",
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/les-conserves',
  },
  /* TARTE PRALINE */
  {
    langue: '🇫🇷',
    nom: 'Pâtissons notre Tarte aux pralines roses',
    prix: '65,00 € / par personne',
    durée: 'Durée prévue : 2h',
    description:
      'Tarte aux pralines roses : Apprenez à faire le fameux gâteau traditionnel lyonnais avec un chef pâtissier. vous apprendrez les astuces des pros pour ce cours de pâtisserie d’environ 2h00. Après le cours pratique vous repartirez avec votre belle boite pâtissière comme si vous sortiez d’une Pâtisserie chic.',
    language: '🇬🇧',
    name: 'Let’s bake our pink praline tart',
    price: '65,00 € / per person',
    duration: 'Expected duration : 2h',
    ukDescription:
      'Lyonnaise Pink Praline tart : Learn how to make the famous traditional Lyonnais cake with a pastry chef. you will learn the tricks of the pros for this pastry course of about 2 hours. After the practical lesson, you will leave with your beautiful pastry box as if you had just left a chic pastry shop.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/tarte-pralines-roses-dfe-lyon',
  },
  /* REPAS ENSEMBLE */
  {
    langue: '🇫🇷',
    nom: 'Un plat et repas ensemble, 5 personnes min.',
    prix: '55,00 € / par personne',
    durée: 'Durée prévue : 3h',
    description:
      'Venez passer un moment convivial en équipe, pour découvrir une recette, originale ou classique, à réaliser ensemble. Après l’atelier cuisine votre plat vous sera servi accompagné d’une entrée et d’un dessert du restaurant. Ce cours de cuisine vise à passer un moment agréable, en groupe avant de retourner à vos occupations quotidiennes.',
    language: '🇬🇧',
    name: 'A dish and meal together, 5 Person min.',
    price: '55,00 € / per',
    duration: 'Expected duration: 3h',
    ukDescription:
      'Come and have a friendly time as a team, to discover a recipe, original or classic, to make together. After the cooking workshop, your dish will be served to you with a starter and a dessert from the restaurant. This cooking class aims to have a pleasant time in a group before returning to your daily activities. group of 5 to 10 people.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/decoupe-de-volaille',
  },
  /* PARENTHESE CHEF */
  {
    langue: '🇫🇷',
    nom: 'Une parenthèse exclusive avec le Chef',
    prix: '520,00 €',
    durée: '5 heures',
    description:
      'Un chef pour moi tout seul ! Cette série de cours de cuisine ou pâtisserie est un bon moyen de se perfectionner aux techniques de cuisine ou de pâtisserie avec un chef de cuisine confirmé. Que ce soit pour vous perfectionner sur une recette précise ou pour une technique plus compliquée, le chef Thierry-Rodolphe sera là pour vous montrer les bonnes pratiques, les moyens les plus simples ou les plus académiques de les réaliser. Ce temps peut aussi bien vous servir à apprendre les bases de la cuisine végétarienne mais qu’à vous perfectionner dans le cadre de votre préparation aux examens.',
    language: '🇬🇧',
    name: 'Tailor-made private lessons',
    price: '520,00 €',
    duration: '5 hours',
    ukDescription:
      'A chef all to myself! This series of cooking or pastry classes is a good way to improve your cooking or pastry techniques with an experienced chef. Whether it is to improve on a specific recipe or for a more complicated technique, chef Thierry-Rodolphe will be there to show you good practices, the simplest or most academic ways to achieve them. This time can be used both to learn the basics of vegetarian cooking and to improve your skills as part of your exam preparation.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/une-parenthèse-exclusive-avec-le-chef',
  },
  /* ENFANTS & ADOS */
  {
    langue: '🇫🇷',
    nom: 'Cours cuisine Enfant ou Ados',
    prix: '44,00 €',
    durée: 'Durée prévue : 2h',
    description:
      'Cette série de cours de cuisine ou pâtisserie s’adressant aux enfants, sort des sentiers battus afin de favoriser la convivialité et le côté ludique des recettes. Les cours destinés « Ados » seront plus basés sur des recettes nutrition et des recettes facilement réalisables. Même si les bases sont identiques que les adultes, c’est le coté ludique du partage qui est mis en avant. Les recettes sont étudiées pour les petites mains et iront donc à l’essentiel pour être le plus facilement reproductibles. Dans le cadre d’un goûter d’anniversaire, nous ferons tout pour qu’ils passent un bon moment.',
    language: '🇬🇧',
    name: 'Cooking classes for kids or teenagers',
    price: '44,00 €',
    duration: 'Expected duration : 2h',
    ukDescription:
      'This series of cooking or baking classes aimed at children, goes off the beaten track in order to promote conviviality and the fun side of the recipes. The courses aimed at “Teenagers” will be based more on nutrition recipes and easily made recipes. Even if the basics are the same as adults, it is the fun side of sharing that is highlighted. The recipes are designed for little hands and will therefore go to the essentials to be as easily reproducible as possible. As part of a birthday snack, we will do everything to ensure they have a good time.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/cours-cuisine-enfant-ou-ados',
  },
  /* CROISSANT FRANCAIS */
  {
    langue: '🇫🇷',
    nom: 'Croissant au coeur de la pâtisserie française',
    prix: '120,00 € / par personne',
    durée: 'Durée prévue : 3h30',
    description:
      "Cela semble être une expérience délicieuse et enrichissante. La confection des croissants au beurre est en effet un art exquis, et il est fascinant de pouvoir en apprendre les secrets sous la guidance d'un Chef boulanger.",
    language: '🇬🇧',
    name: 'Croissant craft : At the heart of French Pastry',
    price: '120,00 € / per person',
    duration: 'Pastry class: 3h30',
    ukDescription:
      "This seems to be a delightful and enriching experience. Crafting butter croissants is indeed an exquisite art, and it's fascinating to be able to learn its secrets under the guidance of a master baker.",
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/croissant-au-cœur-de-la-pâtisserie-française',
  },
  /* RAVIOLIS PARTY */
  {
    langue: '🇫🇷',
    nom: 'Raviolis Party',
    prix: '55,00 € / par personne',
    durée: 'Durée prévue : 2h',
    description:
      "Découvrez le plaisir de créer vos propres raviolis lors de notre cours de cuisine, imprégné d'une atmosphère conviviale et joyeuse. Plongez dans l'univers des saveurs italiennes authentiques tout en apprenant les secrets de la fabrication artisanale des raviolis. Une expérience gourmande et divertissante qui éveillera vos sens et laissera des souvenirs délicieux à partager.",
    language: '🇬🇧',
    name: 'Raviolis Party',
    price: '55,00 € / per person',
    duration: 'Expected duration: 2h',
    ukDescription:
      'Experience the joy of crafting your own ravioli in our cooking class, infused with a friendly and cheerful atmosphere. Immerse yourself in the world of authentic Italian flavors while learning the secrets of handmade ravioli. A gourmet and entertaining experience that will awaken your senses and create delightful memories to share.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/raviolis-partie',
  },
  /* PATE EN CROUTE */
  {
    langue: '🇫🇷',
    nom: 'Réalisez votre pâté en croute',
    prix: '160,00 € / par personne',
    durée: 'Durée prévue : 4h',
    description:
      'Participez à une expérience culinaire unique en créant votre propre pâté en croûte sous la direction de notre chef. Explorez la tradition avec la préparation de la pâte et le choix des viandes, tout en laissant place à votre créativité pour composer une farce innovante.',
    language: '🇬🇧',
    name: 'Pâté en croute: A Culinary Adventure in Tradition and Creativity',
    price: '160,00 € / per person',
    duration: 'Expected duration: 4h',
    ukDescription:
      'Join a unique culinary experience as you craft your own pâté en croûte under the guidance of our chef. Dive into tradition with dough preparation and meat selection, while giving room for your creativity to shape an innovative filling. Depart with a delightful pâté en croûte, blending the best of tradition and culinary creativity.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/réalisez-votre-pâté-en-croute',
  },
  /* STEAK ET GALETTE */
  {
    langue: '🇫🇷',
    nom: 'Steak et galette vegetal',
    prix: '55,00 € / par personne',
    durée: 'Durée prévue : 2h',
    description:
      "Découvrez notre cours de cuisine exclusif dédié aux galettes végétales, où la créativité culinaire rencontre la conscience environnementale. Apprenez à concocter des steaks savoureux à base d'ingrédients végétaliens de qualité.",
    language: '🇬🇧',
    name: 'Vegan Workshop',
    price: '55,00 € / per person',
    duration: 'Expected duration: 2h',
    ukDescription:
      'Discover our exclusive cooking class dedicated to plant-based patties, where culinary creativity meets environmental consciousness. Learn how to whip up delicious vegan steaks using high-quality plant-based ingredients. Join us for a flavorful journey that combines the joy of cooking with a commitment to a greener, more sustainable lifestyle.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/steak-et-galette-vegetal',
  },
  /* ATELIER PATISSERIE */
  {
    langue: '🇫🇷',
    nom: 'Atelier Pâtisserie',
    prix: '90,00 € / par personne',
    durée: 'Durée prévue : 2h30',
    description:
      'Tours de mains sucrés : Un chef pâtissier formé dans de grandes maisons vous attend dans une atmosphère amicale et détendue pour un cours de pâtisserie d’environ 2h30. Apprenez à faire des gâteaux traditionnels ou modernes français comme ceux des Pâtissiers. Ces cours conçus pour les amateurs de pâtisserie sont avant tout un moyen de s’amuser tout en apprenant les astuces des pros et en dégustant un verre de vin, une tasse de thé ou un café. Après le cours pratique vous repartirez avec votre belle boite pâtissière comme si vous sortiez d’une Pâtisserie chic.',
    language: '🇬🇧',
    name: 'Pastry Workshop',
    price: '90,00 € / per person',
    duration: 'Pastry class: 2h30',
    ukDescription:
      'Sweet tricks : A pastry chef trained in large houses awaits you in a friendly and relaxed atmosphere for a pastry lesson lasting around 2h30. Learn how to make traditional or modern French cakes like those of the Pâtissiers. These courses designed for pastry lovers are above all a way to have fun while learning the tricks of the pros and enjoying a glass of wine, a cup of tea or a coffee. After the practical lesson, you will leave with your beautiful pastry box as if you had just left a chic pastry shop.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/atelier-pâtisserie',
  },
  /* ABONNEMENT COURS */
  /*   {
    langue: '🇫🇷',
    nom: 'Abonnement 6 cours : les bases',
    prix: '250,00 € / par personne',
    durée: 'Durée prévue : 6 x 2h',
    description:
      "Cet atelier culinaire a pour objectif d'enseigner les principes fondamentaux de la cuisine, les gestes techniques essentiels, et les meilleures méthodes de cuisson. Ouvert à tous les amateurs de cuisine, il offre une expérience pratique où les participants repartent avec leur plat prêt à déguster.",
    language: '🇬🇧',
    name: ' Subscription for 6 classes: The Basics',
    price: '250,00 € / per person',
    duration: 'Expected duration: 6 x 2h',
    ukDescription:
      'This culinary workshop aims to teach the fundamental principles of cooking, essential technical skills, and the best cooking methods. Open to all cooking enthusiasts, it provides a hands-on experience where participants leave with their dish ready to be enjoyed.',
    lieu: '16 quai Romain Rolland, 69005 LYON (France)',
    url: 'my-site-5-clyon/product-page/decoupe-et-preparations-debases',
  }, */
];

export default CoursParticuliers;
